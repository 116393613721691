<template>
  <sub-page
      :title="$t('General')"
      icon="mdi-cogs"
      v-model="$store.state.settings.pageData"
  >
    <ws-accordion
        class="mt-5"
        :items="navigationItems"
    >
      <!-- Actions-->
      <template #action.site>

        <ft-select
            :value="!$store.state.business.selectedBusiness.disable_public"
            @input="editSitePublishStatus"
            :items="CHOICE_ACTIVE"
            class="pointer"
        >
          <div style="min-width: 150px" class="d-flex justify-end">
          <v-chip
              dark
              :color="wsACCENT"
              :style="$store.state.business.selectedBusiness.disable_public ? 'opacity : 0.5' : ''"
              class="px-1 justify-end font-weight-medium pointer mr-5"
          >
            <v-icon  class="mr-1" >
              {{  !$store.state.business.selectedBusiness.disable_public ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
            </v-icon>
            <h5 class="mx-2">{{ !$store.state.business.selectedBusiness.disable_public ? $t('Active') : $t('NotActive')   }}</h5>
            <v-icon>mdi-menu-down</v-icon>
          </v-chip>
          </div>
        </ft-select>
      </template>
      <template #action.alias="{forcedExpand , item}">
        <div style="min-width: 150px" class="d-flex justify-end">
          <v-chip
              @click="openAliasDialog(forcedExpand)"
              dark
              :color="wsACCENT"
              class="px-1 justify-end font-weight-medium pointer mr-5"
          >
            <v-icon small class="ml-2">mdi-{{ !item.expand ? 'pencil' : 'close' }}</v-icon>
            <h5  class="mx-2"> {{ !item.expand  ? $t('Edit') : $t('Cancel') }}</h5>

          </v-chip>
        </div>

      </template>
      <template #action.description="{forcedExpand , item}">
        <div style="min-width: 150px" class="d-flex justify-end">
          <v-chip
              @click="openDescription(forcedExpand)"
              dark
              :color="wsACCENT"
              class="px-1 justify-end font-weight-medium pointer mr-5"
          >
            <v-icon small class="ml-2">mdi-{{ !item.expand ? 'pencil' : 'close' }}</v-icon>
            <h5  class="mx-2"> {{ !item.expand  ? $t('Edit') : $t('Cancel') }}</h5>
          </v-chip>
        </div>

      </template>
      <template #action.image="{forcedExpand , item}">
        <div style="min-width: 150px" class="d-flex justify-end">
          <v-chip
              @click="forcedExpand()"
              dark
              :color="wsACCENT"
              class="px-1 justify-end font-weight-medium pointer mr-5"
          >
            <v-icon small class="ml-2">mdi-{{ !item.expand ? 'pencil' : 'close' }}</v-icon>
            <h5  class="mx-2"> {{ !item.expand  ? $t('Edit') : $t('Cancel') }}</h5>

          </v-chip>
        </div>

      </template>
      <template #action.type>
        <div class="fill-height align-center d-flex  pr-5">

          <ws-select
              :placeholder="$t('homepage.register.business.type.placeholder')"
              v-model="businessType"
              :items="businessTypesSelect"
              width="300"
              hide-details
          />
        </div>

      </template>

      <!-- Items-->
      <template #item.site>
        <div class="pa-5">
          <h5 class="font-weight-regular mt-6" :style="`color : ${wsACCENT}`">
            {{ $t('homepage.register.business.public_site.description') }}
          </h5>
          <ws-switch :placeholder="$t('Enabled')" />
          <v-row v-if="false">
            <v-col cols="4">
              <div class="d-flex fill-height align-center">
                <ws-switch :placeholder="$t('Enabled')" />
              </div>
            </v-col>
            <v-col cols="8">
              <h5 class="font-weight-regular mt-6" :style="`color : ${wsACCENT}`">
                {{ $t('ChangeBusinessAliasWarning') }}
              </h5>
            </v-col>
          </v-row>

        </div>
      </template>
      <template #item.alias>
        <div class="pa-5">
          <v-row>
            <v-col cols="6">
              <div class="d-flex">
                <ws-text-field
                    v-model="alias"
                    :label="$t('Alias')"
                    :error-messages="aliasError"
                    @input="aliasError = null"
                    :error="!!aliasError"
                    :hide-details="false"
                    width="100%"
                />
                <ws-button
                    @click="openAliasChangeDialog"
                    :disabled="alias===$store.state.business.selectedBusiness.alias || !!aliasError || !alias || alias.length < 3"
                    label="Save"
                    class="mt-7 ml-2"
                />
              </div>


            </v-col>
            <v-col cols="6">
              <h5 class="font-weight-regular mt-6" :style="`color : ${wsACCENT}`">
                {{ $t('ChangeBusinessAliasWarning') }}
              </h5>
            </v-col>
          </v-row>
        </div>

      </template>
      <template #item.description>

        <div class="pa-5 ">
          <v-sheet
              v-for="(lang,i) in langs" :key="i"
              :color="wsLIGHTCARD"
              style="position:relative"
              class="d-flex flex-row align-center wsRoundedLight mb-4 pa-6 pr-10"
          >

            <v-sheet
                @click="openEditLanguage(lang)"
                :color="wsATTENTION"
                class="pa-2 d-flex align-center justify-center mr-4 pointer"
                v-ripple
                width="32" height="32"
                min-width="32" min-height="32"
                style="border-radius: 50%; position: absolute; right: -32px;"
            >
              <v-icon :size="20" dark>mdi-pencil</v-icon>
            </v-sheet>

            <v-sheet
                :color="wsBACKGROUND"
                class="pa-2 d-flex align-center justify-center mr-4"
                width="40" height="40"
                min-width="40" min-height="40"
                style="border-radius: 50%"
            >
              <h4 :style="`color : ${wsDARKER}`">{{ lang.toUpperCase() }}</h4>
            </v-sheet>

            <h3 style="width: 303px; min-width: 303px" :style="`color : ${!!names[lang] ? wsDARKER : wsDARKLIGHT}`">{{ names[lang] || $t('EnterName') }}</h3>

            <h4 class="font-weight-regular flex-grow-1"  :style="`color : ${!!descriptions[lang] ? wsDARKER : wsDARKLIGHT}`">
              {{ descriptions[lang] }}
            </h4>

          </v-sheet>

          <ws-button
              @click="openNewLanguage"
              label="AddLanguage"
              left-icon="mdi-plus-circle-outline"
          />

        </div>
      </template>
      <template #item.image>
        <div class="pa-5 d-flex">
          <v-sheet max-width="550" width="100%" class="mr-4">

            <image-param-picker
                @input="updateBusinessImage"
                v-model="$store.state.business.selectedBusiness.img"
                no-border
                height="240"
            />

          </v-sheet>
          <h5 class="font-weight-regular mt-6" :style="`color : ${wsACCENT}`">
            {{ $t('business_image_title') }}
          </h5>
        </div>

      </template>

      <template #item.type>
        <ws-text-field
            :label="$t('UA')"
        />
      </template>

    </ws-accordion>

    <template #dialog>

      <!-- Language Dialog-->
      <add-edit-language-dialog
          v-if="displayLangDialog"
          v-model="displayLangDialog"
          :entity-data="langEntityData"
          :display-delete="langs.length > 1"
          :new-entity="newLang"
          :languages="langs"
          @save="addEditTranslation"
          @delete="deleteLang($event)"
      />

      <!-- Alias Change Dialog-->
      <ws-dialog
          v-model="displayAliasDialog"
          :title="$t('EditBusinessAlias')"
          :save-text="$t('Confirm')"
          @save="saveAlias"
      >
        <h5 class="font-weight-regular">{{  $t('ChangeAliasConfirmationDescription')  }}:</h5>
        <h5 class="mt-2">{{  aliasConfirmationCode  }}</h5>

        <ws-text-field
            v-model="aliasConfirmation"
            class="mt-5"
            :placeholder="$t('EnterConfirmationCode')"
            :error="!!aliasConfirmationError"
            :error-messages="aliasConfirmationError"
            @input="aliasConfirmationError = null"
        />

      </ws-dialog>
    </template>
  </sub-page>
</template>

<script>
import languagesList from "@/mixins/languagesList";
import {mapActions} from "vuex";
import addEditLanguageDialog from "@/components/pages/businessDashboard/businessSettings/UI/addEditLanguageDialog";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
export default {
  name: "settingsSite",
  mixins : [languagesList],
  components : {
    addEditLanguageDialog,
    imageParamPicker
  },
  data() {
    return {
      serviceKeys : {},
      // alias
      alias : '',
      aliasError : null,
      aliasConfirmationError : null,
      aliasConfirmationCode : null,
      aliasConfirmation : null,
      displayAliasDialog : false,
      // description
      langs  : [],
      names : {},
      descriptions : {},
      displayLangDialog : false,
      selectedLang: null,
      langEntityData : {},
      newLang : false
    }
  },
  computed : {
    navigationItems() {
      return [
        { name : this.$t('homepage.register.business.public_site.title') ,
          subtitle : this.$t('homepage.register.business.public_site.tooltip'),
          value : 'site' ,
          icon : 'mdi-web',
          expand : false,
          noExpand: true,
          expanded : false
        },
        { name : this.$t('homepage.register.business.alias.title') ,
          subtitle : this.$t('homepage.register.business.alias.tooltip'),
          value : 'alias' ,
          icon : 'mdi-link',
          expand : false,
          noExpand: true,
          expanded : false
        },
        { name : this.$t('homepage.register.business.name.title') ,
          subtitle : this.$t('homepage.register.business.name.tooltip'),
          value : 'description' ,
          icon : 'mdi-information-outline',
          noExpand: true,
          expanded : false
        },
        { name : this.$t('Image') ,
          subtitle : this.$t('BusinessImageDescription'),
          value : 'image' ,
          icon : 'mdi-image',
          noExpand: true,
          expanded : false
        },
        // { name : this.$t('homepage.register.business.type.title') ,
        //   subtitle : this.$t('homepage.register.business.type.tooltip'),
        //   value : 'type' ,
        //   icon : 'mdi-format-list-bulleted-type',
        //   noExpand: true,
        //   expanded : false
        // },
      ]
    },
    businessTypesSelect() {
      return [
        { text : this.$t("PrivateSchool"      ),   value : 'private_school',    },
        { text : this.$t("BusinessTrainings"  ),   value : 'business_trainings',},
        { text : this.$t("PsychologyCourses"  ),   value : 'psychology_courses',},
        { text : this.$t("ImprovementCourses" ),   value : 'upgrade',           },
        { text : this.$t("ChildCourses"       ),   value : 'children',          },
        { text : this.$t("FitnessSportTeacher"),   value : 'fitnes',            },
        { text : this.$t("ArtCourses"         ),   value : 'art',               },
        { text : this.$t("SchoolTeacher"      ),   value : 'school_teacher',    },
      ]
    }
  },
  methods : {
    ...mapActions('business',  [ 'CHECK_ALIAS' ]) ,
    ...mapActions('settings',  [
        'EDIT_BUSINESS_GENERAL_SETTINGS',
        'GET_BUSINESS_TRANSLATIONS_ARRAY'
    ]),




    async updateBusinessImage(value){
      await this.EDIT_BUSINESS_GENERAL_SETTINGS({ img : value })
    },
    async editSitePublishStatus(value){
      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS({ disable_public : !value })
      if ( !result ) {
        return
      }
      this.$store.state.business.selectedBusiness.disable_public = !value
      if ( !value ) {
        this.notify(this.$t('PublicSiteDisabled') )
      } else {
        this.notify(this.$t('PublicEnabledEnabled') , 'success' )
      }

    },
    openEditLanguage(lang) {
      this.newLang = false
      this.langEntityData = {
        name : this.names[lang],
        description : this.descriptions[lang],
        lang : lang
      }
      this.selectedLang = lang
      this.displayLangDialog = true
    },
    openNewLanguage() {
      this.newLang = true
      this.langEntityData = {}
      this.displayLangDialog = true
    },

    generateCode() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const numbers = '0123456789';

      let code = '';

      // Generate two random letters
      for (let i = 0; i < 2; i++) {
        code += letters.charAt(Math.floor(Math.random() * letters.length));
      }

      // Generate four random numbers
      for (let i = 0; i < 6; i++) {
        code += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }

      return code;
    },

    async deleteLang(lang) {
      this.langs = this.langs.filter(el => el !== lang)
      this.displayLangDialog = false
    },

    async addEditTranslation(data) {
      if ( this.newLang && !this.langs.includes(data.lang) ) {
        this.langs.push(data.lang)
      }
      this.names[data.lang] = data.name
      this.descriptions[data.lang] = data.description
      this.names = this.COPY(this.names)
      this.descriptions = this.COPY(this.descriptions)
      this.displayLangDialog = false
    },

    async openDescription(expand) {
      let result = await this.GET_BUSINESS_TRANSLATIONS_ARRAY()
      if ( !result ) {
        return
      }
      this.langs = result.langs

      if ( this.langs.length > 0 && typeof this.langs[0] === 'object' ) {
        this.langs = [this.$store.state.business.selectedBusiness.lang || 'de']
      }

      this.names = result.names
      this.descriptions = result.descriptions
      expand()
    },

    async saveAlias() {

      if ( this.aliasConfirmationCode !== this.aliasConfirmation ) {
        this.aliasConfirmationError = this.$t('WrongConfirmationCode')
        this.notify(this.$t('WrongConfirmationCode') , 'warning')
        return
      }

      let result = await this.EDIT_BUSINESS_GENERAL_SETTINGS({ alias : this.alias })
      if ( !result ) {
        return
      }
      this.notify(this.$t('BusinessAliasChanged') , 'success')
      this.$store.state.business.selectedBusiness.alias = this.alias
      this.$router.push(this.businessDashLink('settings/general'))
      location.reload()
    },
    async openAliasChangeDialog() {
      if ( !this.alias ) {
        this.aliasError = this.$t('PleaseEnterBusinessAlias')
        this.notify(this.$t('PleaseEnterBusinessAlias') , 'warning')
        return
      }
      if ( !this.alias.match("^[a-zA-Z0-9_-]*$")) {
        this.aliasError = this.$t('AliasRegexError')
        return this.notify(this.$t('AliasRegexError'), 'warning')
      }

      if ( this.alias.length < 3) {
        this.aliasError = this.$t('AliasLengthError')
        return this.notify(this.$t('AliasLengthError'), 'warning')
      }

      if ( !await this.CHECK_ALIAS(this.alias) ) {
        this.aliasError = this.$t('BusinessAliasAlreadyExist')
        return this.notify(this.$t('BusinessAliasAlreadyExist'), 'warning')
      }

      this.aliasConfirmation = ''
      this.aliasConfirmationCode = this.generateCode()
      this.displayAliasDialog = true

    },
    openAliasDialog(expand) {
      this.alias = this.$store.state.business.selectedBusiness.alias
      expand()
    },
    addDescriptionLanguage() {

    },
    addTitleLanguage() {

    },
    initPage() {
      this.serviceKeys.gTag = !!this.$store.state.business.selectedBusiness.gTag
      this.serviceKeys.fPixelId = !!this.$store.state.business.selectedBusiness.fPixelId
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>